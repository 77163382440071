import { tns } from 'tiny-slider';

const TVSection = document.querySelector('.tns-tv');
if (TVSection) {
    new tns({
        container: TVSection,
        controlsContainer: '.tv-controls',
        nav: false,
        controls: true,
        loop: false,
        gutter: 64,
        edgePadding: 32,
        mouseDrag: true,
        responsive: {
            786: {
                fixedWidth: 656,
            },
            1300: {
                edgePadding: 150,
            },
            1400: {
                edgePadding: 50,
            },
            1500: {
                edgePadding: 100,
            },
            1600: {
                edgePadding: 150,
            },
            1700: {
                edgePadding: 200,
            },
            1800: {
                edgePadding: 264,
            },
        },
    });
}

const playerSlider = document.querySelector('.player-slider');
if (playerSlider) {
    new tns({
        container: playerSlider,
        nav: false,
        controls: true,
        loop: true,
        mouseDrag: true,
        controlsContainer: '.player-slider-controls',
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayButtonOutput: false,
        responsive: {
            576: {
                items: 2,
                slideBy: 2,
            },
            992: {
                items: 3,
            },
            1400: {
                items: 4,
            },
        },
    });
}
